import Swiper, {
  Navigation,
  A11y,
  Keyboard,
  Lazy,
  Zoom,
} from "swiper";

import "swiper/css/bundle";

class Carousel {
  constructor(carousel) {
    this.$carousel = carousel;
    this.$indexContainer = document.querySelector(".js-carousel-index");
    this.$caption = null;
    this.setCarousel();
  }

  setCarousel() {
    const swiper = new Swiper(this.$carousel, {
      modules: [Navigation, A11y, Keyboard, Lazy, Zoom],
      init: false,
      zoom: true,
      initialSlide: 1,
      hashNavigation: {
        replaceState: true,
        watchState: true,
      },
      grabCursor: true,
      navigation: {
        nextEl: ".js-carousel-next",
        prevEl: ".js-carousel-prev",
      },
      keyboard: {
        enabled: true,
        onlyInViewport: false,
      },
      preloadImages: false,
      lazy: {
        loadPrevNext: true,
      },
    });

    swiper.on("init", function () {
      let caption = document
        .querySelector(".swiper-slide-active")
        .getAttribute("data-caption");
      document.querySelector(".js-caption").textContent = caption;
    });

    swiper.on("slideChange", function () {
      if (swiper.realIndex < this.slides.length - 1) {
        document.querySelector(".js-carousel-index").textContent =
          swiper.realIndex;
      }
      document.querySelector(".js-caption").textContent = null;
    });

    swiper.on("slideChangeTransitionEnd", function () {
      let caption = document
        .querySelector(".swiper-slide-active")
        .getAttribute("data-caption");
      document.querySelector(".js-caption").textContent = caption;
    });

    // Beginning, previous project
    swiper.on("reachBeginning", function () {
      if (document.querySelector(".js-prev")) {
        var prevLink = document.querySelector(".js-prev").href;
        window.location = prevLink;
      }
    });

    // End, next project
    swiper.on("reachEnd", function () {
      if (document.querySelector(".js-next")) {
        var nextLink = document.querySelector(".js-next").href;
        window.location = nextLink;
      }
    });

    // init Swiper
    swiper.init();
  }
}

export default Carousel;
