import "/src/css/style.scss";

import Navigation from "./modules/Navigation";
import Carousel from "./modules/Carousel";
import FakeLinks from "./modules/FakeLinks";
import Filter from "./modules/Filter"
import DateSelector from "./modules/DateSelector"

import lazySizes from "lazysizes";
import "lazysizes/plugins/respimg/ls.respimg";

// htmx for Sprig
import htmx from "htmx.org";

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}

class marinehugonnier {
  constructor() {
    this.init();
  }

  init() {
    new Navigation();
    new FakeLinks();
      
    document.documentElement.className =
      document.documentElement.className.replace(/\bno-js\b/g, "") + " js ";

    const carouselModules = document.querySelectorAll(".js-carousel");
    if (!!carouselModules) {
      carouselModules.forEach((carouselModule) => new Carousel(carouselModule));
    }

    const filter = document.querySelector(".js-filter");
    if (!!filter) {
      new Filter(filter);
    }

    const dateSelector = document.querySelector(".js-date-selector");
    if (!!dateSelector) {
      new DateSelector(dateSelector);
    }
  }
}

(function () {
  document.addEventListener("touchstart", function () {}, true);
  new marinehugonnier();
})();
