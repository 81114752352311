class Filter {
  constructor() {
    this.setDomElements();
    this.setupFilter();
  }

  setDomElements() {
    this.$categoryFilters = document.querySelector(".js-filter");
    this.$filterLinks = document.querySelectorAll(".js-filter span");
    this.$postsContainer = document.getElementById("index");
    this.$posts = document.querySelectorAll(".c-works__work");
  }

  // Show an element
  show(e) {
    e.classList.remove("is-hidden");
  }

  // Hide an element
  hide(e) {
    e.classList.add("is-hidden");
  }

  filterPosts(event) {
    // console.log('clicked');

    // Get originator element
    let element = event.target;
    // Remove ciurrent is-active
    let currentIsActive = this.$categoryFilters.querySelector(".is-active");

    currentIsActive.classList.remove("is-active");
    // Make this element selected
    element.classList.add("is-active");
    // Get category to show
    let category = element.getAttribute("data-href").split("#")[1];

    // Get posts of current category
    var postsFromCategory = document.querySelectorAll("." + category);
    // console.log(category);

    if (category !== "all") {
      // Hide all posts
      for (var i = 0, len = this.$posts.length; i < len; i++) {
        this.hide(this.$posts[i]);
      }
      // Show only posts of selected category
      for (var i = 0, len = postsFromCategory.length; i < len; i++) {
        this.show(postsFromCategory[i]);
      }
    } else {
      // Show all posts
      for (var i = 0, len = this.$posts.length; i < len; i++) {
        this.show(this.$posts[i]);
      }
    }
  }
  setupFilter() {
    this.$filterLinks.forEach((element) => {
      element.addEventListener("click", (event) => {
        event.preventDefault();
        this.filterPosts(event);
      });
    });
  }
}

export default Filter;
