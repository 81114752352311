// change from document to body

class Navigation {
  constructor() {
    this.setDomElements();
    this.setNavigation();
  }

  setDomElements() {
    this.$toggle = document.querySelector(".js-masthead__toggle");
    this.$menu = document.querySelector(".c-masthead");
    this.$nav = document.querySelector(".c-masthead__nav");
  }

  showNavigation() {
    this.$menu.classList.add("is-open");
  }

  hideNavgigation() {
    this.$menu.classList.remove("is-open");
  }

  escapeModal(Event) {
    if (Event.key === "Escape") {
      this.hideNavgigation();
    }
  }

  toggleNavigation() {
    // Checks if already opened, then closes
    if (document.body.classList.contains("is-open")) {
      this.hideNavgigation();
    }
    // Opens the menu
    else {
      this.showNavigation();
    }
  }

  setNavigation() {
    document.addEventListener("keyup", (event) => {
      this.escapeModal(event);
    });

    this.$toggle.addEventListener("click", () => {
      this.toggleNavigation();
    });

  }
}

export default Navigation;
