class FakeLinks {
  constructor() {
    this.setDomElements();
    this.setupFakeLinks();
  }

  setDomElements() {
    this.$links = document.querySelectorAll("[data-href]");
  }

  setupFakeLinks() {
    this.$links.forEach((link) => {
      link.addEventListener("click", function (event) {
        let realLink = event.target.getAttribute("data-href");
        window.location = realLink;
      });
    });
  }
}

export default FakeLinks;
