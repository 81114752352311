class DateSelector {
  constructor(dateSelector) {
    this.$selector = dateSelector;
    this.setDomElements();
    this.setupDateSelector();
  }

  setDomElements() {
    // this.$links = document.querySelectorAll("[data-href]");
    this.$links = this.$selector.querySelectorAll("[data-year],[data-month]");
    this.$activeYear =
      this.$selector.querySelector("[data-year].is-active") ??
      this.$selector.querySelector("[data-year]");
  }

  openSelector(dateSelector) {
    // console.log(this.$activeYear);
    dateSelector.classList.add("is-open");
  }

  closeSelector() {
    const selected = document.querySelector("[data-year].is-active");
    if (selected) {
      selected.classList.remove("is-active");
      this.$activeYear.classList.add("is-active");
    }
    this.$selector.classList.remove("is-open");
    this.$selector.blur();
  }

  setSelectorDate(year, month) {
    const $activeYear = document.querySelector("[data-year].is-active");
    const $newActiveYear = document.querySelector(`[data-year='${year}']`);

    if ($activeYear && $newActiveYear && $activeYear !== $newActiveYear) {
      $activeYear.classList.remove("is-active");
      $newActiveYear.classList.add("is-active");
    }


    const $activeMonth = document.querySelector("[data-month].is-active");
    const $newActiveMonth = document.querySelector(`[data-month='${month}']`);

    if ($activeMonth && $newActiveMonth && $activeMonth !== $newActiveMonth) {
      $activeMonth.classList.remove("is-active");
      $newActiveMonth.classList.add("is-active");
    }
  }
  
  setupDateSelector() {
    this.$links.forEach(function (link, index) {
      link.addEventListener("click", function (event) {
        // If year link
        // remove other active link
        // set to active
        // console.log(link.hasAttribute("data-year"));

        if (link.hasAttribute("data-year")) {
          const active = document.querySelector("[data-year].is-active");
          if (active) {
            active.classList.remove("is-active");
          }
          event.currentTarget.classList.add("is-active");
        } else {
          // else, it's a month link,
          // remove other active link,
          // set to active
          // go to month,
          // close
          const active = document.querySelector("[data-month].is-active");
          if (active) {
            active.classList.remove("is-active");
          }
          event.currentTarget.classList.add("is-active");
          this.closeSelector();

          var month = event.currentTarget.getAttribute("data-month");
          this.scrollToMonth(month);
        }
      });

      link.addEventListener("keypress", function (event) {
        if (link.hasAttribute("data-year")) {
          const active = document.querySelector("[data-year].is-active");
          if (active) {
            active.classList.remove("is-active");
          }
          event.currentTarget.classList.add("is-active");
        } else {
          const active = document.querySelector("[data-month].is-active");
          if (active) {
            active.classList.remove("is-active");
          }
          event.currentTarget.classList.add("is-active");
          this.closeSelector();

          var month = event.currentTarget.getAttribute("data-month");
          this.scrollToMonth(month);
        }
      });
    });

    this.$selector.addEventListener("click", (event) => {
        this.openSelector(this.$selector)
    }, false);

    document.addEventListener("click", (event) => {
      let isClickInside = this.$selector.contains(event.target);
      if (!isClickInside) {
        this.closeSelector(this.$selector);
      }
    });

    document.addEventListener("click", (event) => {
      let isClickInside = this.$selector.contains(event.target);
      if (!isClickInside) {
        this.closeSelector(this.$selector);
      }
    });
    

    document.onkeydown = function (evt) {
      evt = evt || window.event;
      var isEscape = false;
      if ("key" in evt) {
        isEscape = evt.key === "Escape" || evt.key === "Esc";
      } else {
        isEscape = evt.keyCode === 27;
      }
      if (isEscape) {
        this.closeSelector();
      }
    };

  
    // Intersection Observer callback
      const intersectionCallback = (entries, intersectionObserver) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // console.log('Element is in viewport:', entry.target);
            // Optionally, stop observing the element
            // intersectionObserver.unobserve(entry.target);
            let year = entry.target.getAttribute('data-year');
            let month = entry.target.getAttribute('data-month');
  
            // Call setSelectorDate
            this.setSelectorDate(year, month);
          }
        });
      };

      // Create an Intersection Observer
      const intersectionObserver = new IntersectionObserver(intersectionCallback);

      // MutationObserver callback
      const mutationCallback = (mutationsList) => { // Removed the second parameter to avoid shadowing
        for(const mutation of mutationsList) {
          if (mutation.type === 'childList') {
            mutation.addedNodes.forEach(node => {
              // Check if the added node is an element and should be observed
              if (node.nodeType === 1) { // ELEMENT_NODE
                // console.log('New node added:', node);
                // Start observing the new element with the Intersection Observer
                intersectionObserver.observe(node); // Use the correctly named variable here
              }
            });
          }
        }
      };

      // Create a MutationObserver
      const mutationObserver = new MutationObserver(mutationCallback);

      // Start observing a container element for DOM changes
      const container = document.querySelector('#diary');
      mutationObserver.observe(container, { childList: true, subtree: true });

  }
}

export default DateSelector;

